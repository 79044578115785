import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const DrivewaysPatios = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Professional Driveway and Patio Services in Pompano Beach, FL</Heading>
                <Text>Pompano Concrete is your go-to expert for driveway and patio installations in Pompano Beach, FL.
                    Our team is dedicated to designing and creating functional, aesthetically pleasing driveways and
                    patios that not only enhance the look of your property but also add to its value. We understand the
                    importance of these features in your home or business and are committed to delivering top-quality
                    results.</Text>
                <Text>Whether you are looking for a new installation, an upgrade, or a complete makeover, we have the
                    expertise and materials to bring your vision to life. Our driveways and patios are crafted to
                    withstand the local climate and provide lasting beauty and functionality.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Custom Design Solutions:</b> We offer a range of design options, from traditional
                        concrete finishes to modern decorative styles, ensuring your driveway or patio perfectly matches
                        your property’s aesthetic and functional needs.</ListItem>
                    <ListItem><b>Durable and High-Quality Materials:</b> Our use of high-quality concrete and
                        installation techniques ensures that your driveway or patio is not only visually appealing but
                        also durable and long-lasting.</ListItem>
                    <ListItem><b>Seamless Integration:</b> We ensure that your new driveway or patio integrates
                        seamlessly with your existing landscaping and outdoor features, enhancing the overall appeal of
                        your property.</ListItem>
                    <ListItem><b>Expert Installation:</b> Our skilled professionals are equipped with the latest tools
                        and techniques to efficiently install your driveway or patio, ensuring minimal disruption and
                        timely completion of the project.</ListItem>
                    <ListItem><b>Enhanced Curb Appeal:</b> A well-designed driveway or patio can significantly improve
                        the curb appeal of your property, making a great first impression on visitors and potential
                        buyers.</ListItem>
                    <ListItem><b>Competitive Pricing:</b> We offer transparent pricing and detailed estimates, ensuring
                        that you receive the best value for our high-quality driveway and patio services.</ListItem>
                </UnorderedList>
                <Text>
                    At Pompano Concrete, we are committed to delivering excellence in every driveway and patio project
                    we undertake in Pompano Beach, FL. Contact us today to discuss your project and see how we can
                    transform your outdoor space.
                </Text>
            </Stack>
        </Box>
    )
}