import {
    Box,
    Button,
    Container,
    Grid,
    Heading,
    Image,
    Link,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import concreteMeta from "../assets/images/concreteMeta.jpg";
import concrete10 from "../assets/images/concrete3.webp";
import {posts} from "./posts";
import {CommercialConcrete} from "../Components/CommercialConcrete/CommercialConcrete";
import {DecorativeConcrete} from "../Components/DecorativeConcrete/DecorativeConcrete";
import {ResidentialConcrete} from "../Components/ResidentailConcrete/ResidentialConcrete";
import {ConcreteRepairs} from "../Components/ConcreteRepairs/ConcreteRepairs";
import {DrivewaysPatios} from "../Components/DrivewaysPatios/DrivewaysPatios";
import {ConcreteInstallation} from "../Components/ConcreteInstallation/ConcreteInstallation";
import {AiFillTool} from "react-icons/ai";
import {BsShieldFillCheck} from "react-icons/bs";
import {GiConcreteBag} from "react-icons/gi";
import {FaBuilding, FaHome, FaPuzzlePiece, FaRegCalendarAlt, FaRoad,} from "react-icons/fa";
import {MdBuild} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {StampedConcrete} from "../Components/StampedConcrete/StampedConcrete";
import {faqs} from "./faqs";

const phoneNumber = "(754) 201-2329";
const telLink = "tel:7542012329";

export const SiteData = {
    city: "Pompano Beach, FL",
    hoursOfOpperation: "Monday - Friday 9AM-5PM",
    phoneNumber,
    telLink,
    buttonCtaText: "Connect Now for High-Quality Concrete Solutions!",
    emailFooterText: `Whether you're looking to install a new stamped concrete patio or need repairs on your driveway, our expert team in Pompano Beach is ready to assist you. Call us at ${phoneNumber}`,
    keywords:
        "Pompano Beach, Concrete Services, Pompano Concrete, Quality Concrete, Residential Concrete, Commercial Concrete, Concrete Contractors in Florida.",
    footerText: "© 2023 Pompano Concrete, All rights reserved.",
    ogImage: concreteMeta,
    navLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services/",
        },
        {
            title: "Blog",
            href: "/blog/",
        },
        {
            title: "About",
            href: "/about/",
        },
        {
            title: "Contact",
            href: "/contact/",
        },
        {
            title: "Careers",
            href: "/careers/",
            hideFromTopNav: true,
        },
    ],
    careers: {
        url: "https://www.pompanoconcrete.com/careers/",
        metaData: {
            title:
                "Join Our Team at Pompano Concrete - Pompano Beach, FL",
            description:
                "Exciting career opportunities in concrete services at Pompano Concrete. Join our skilled team in Pompano Beach, FL.",
        },
        h1: "We're Hiring - Become a Part of Pompano Concrete",
        h2: [
            "Why Join Pompano Concrete?",
            "Current Openings in Concrete Services",
            "Our Commitment to Employee Development and Satisfaction",
        ],
        headingText: {
            whyJoin:
                "Discover a workplace where your expertise in concrete is appreciated and your professional growth is nurtured. Join a team dedicated to delivering top-notch concrete services in Pompano Beach, FL.",
            openings:
                "Check out our current job openings in various concrete services roles and find a position that matches your skills and career goals.",
            commitment:
                "At Pompano Concrete, we are invested in our team's development, offering a supportive and dynamic work environment. Your professional success is integral to our company's growth.",
        },
        content: [
            "Welcome to the Careers page at Pompano Concrete, where your career in the concrete industry can reach new heights. We're more than a team; we're a community dedicated to excellence in concrete services. Join us in making a significant impact in Pompano Beach, FL.",
            "At Pompano Concrete, our strength lies in our people. We are on the lookout for dedicated professionals who are passionate about concrete work. Whether you have years of experience or are just starting, we offer a workplace where your skills will be developed and appreciated.",
            "Our employees enjoy a nurturing culture that promotes both personal and professional growth. We provide comprehensive training, competitive compensation, and opportunities to work with advanced concrete technology and techniques.",
            "As a key player in the Pompano Beach community, we pride ourselves on delivering services with integrity and professionalism. We support our team with a positive work environment, teamwork built on respect, and opportunities for career advancement.",
            "Ready to join a leading concrete service provider in Pompano Beach, FL? Explore our current job openings and find where you fit into our expanding team. From on-site work to customer service and administration, there's a place for you at Pompano Concrete.",
            "Discover your next career opportunity with us. Be part of a team that's revolutionizing the concrete industry with quality workmanship and customer satisfaction. At Pompano Concrete, we're not just a company; we're a community. Apply today and build your career with us.",
        ],
    },
    mobileNavLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services/",
        },
        {
            title: "Blog",
            href: "/blog/",
        },
        {
            title: "Contacst",
            href: "/contact/",
        },
        {
            title: "Concrete Repair",
            href: "/concrete-repair/",
        },
        {
            title: "Concrete Installation",
            href: "/concrete-installation/",
        },
        {
            title: "Commercial Concrete",
            href: "/commercial-concrete/",
        },
        {
            title: "Residential Concrete",
            href: "/residential-concrete/",
        },
        {
            title: "Decorative Concrete",
            href: "/decorative-concrete/",
        },
        {
            title: "Driveways & Patios",
            href: "/driveways-patios/",
        },
    ],
    signupForm: {
        intro: () => (
            <Text>
                Pompano Concrete, a leader in providing comprehensive concrete services
                in Pompano Beach, Florida, takes pride in our high-quality workmanship
                for both residential and commercial projects. We are just a{" "}
                <Link href={"tel:7542012329"} textDecoration={"underline"}>
                    phone call away
                </Link>
                .
            </Text>
        ),
        servicesTitle: "What We Offer:",
    },
    homepage: {
        url: "https://www.pompanoconcrete.com",
        metaData: {
            title:
                "Trusted Concrete Contractor - Pompano Concrete | 754-201-2329",
            description:
                "Professional concrete services in Pompano Beach, FL. Driveways, stamped concrete and more. Call 754-201-2329 for a free estimate.",
        },
        h1: "Premier Concrete Contractor in Pompano Beach, Florida",
        subtitle:
            "Dedicated to Excellence in Residential and Commercial Concrete Solutions",
        servicesTitle: "Why Choose Pompano Concrete?",
        heading: "Personal Touch",
        subheading: "ABOUT POMPANO CONCRETE",
        detail:
            "Pompano Concrete is all about delivering customized, effective solutions to every Pompano Beach dweller.",
        description: (
            <Text>
                Here at Pompano Concrete, we're not just about getting the job done;
                we're about doing it with flair and precision. Our squad, serving the
                Pompano Beach community, tackles everything from cozy residential
                projects to grand commercial ventures. Whether it's the art of stamped
                concrete or constructing resilient structures, we're all about crafting
                concrete solutions that are not only strong but also have that wow
                factor, making us the top dogs in concrete contracting around Pompano
                Beach.
            </Text>
        ),
        img: "images/about/call_img.png",
        values: [
            {
                id: 0,
                number: 500,
                name: "Projects Completed",
                icon: MdBuild,
            },
            {
                id: 1,
                number: 20,
                name: "Experience in Industry",
                icon: FaRegCalendarAlt,
            },
            {
                id: 2,
                number: 98,
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            // {
            //     "id": 3,
            //     "number": 10,
            //     "name": "Awards and Recognitions",
            //     "icon": MdEmojiEvents
            // },
        ],
        content: [
            <Box>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Meet Pompano Concrete: Pompano Beach's Finest in Concrete Contracting
                </Heading>
                <Text>
                    Hey Pompano Beach! Thanks for checking us out. At Pompano Concrete, we
                    are your neighborhood heroes in the world of concrete contracting.
                    Here in sunny Pompano Beach, Florida, we're redefining the concrete
                    game. Dreaming of a show-stopping stamped concrete driveway or a
                    quaint backyard patio? That's where we shine! Our team's blend of
                    speed, affordability, and reliability has made us the favorite
                    <a href={"https://www.enhancedconcrete.net/"} target={"_blank"} rel={"noreferrer"}>concrete
                        contractor</a> in town. Contact us today for the best in florida concrete contractors.
                </Text>
                <Text>
                    But wait, there's more to us at Pompano Concrete than just a concrete company pouring and
                    shaping concrete. We're all about creating spots where memories are
                    made, businesses grow, and communities come together. Be it a peaceful
                    walkway in your garden or a solid base for your latest business idea,
                    our crew of savvy{" "}
                    <a
                        href={"https://www.euclidconcrete.com"}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        concrete contractors
                    </a>
                    , armed with the latest and greatest in concrete tech, is here to make
                    your concrete dreams a reality.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Pompano Concrete: Your One-Stop Shop for All Things Concrete
                </Heading>
                <Text>
                    Got a concrete conundrum in Pompano Beach, FL? No sweat, Pompano
                    Concrete has got your back. Give us a ring at{" "}
                    <Link href={"tel:7542012329"}>(754) 201-2329</Link>, and you'll tap
                    into a team that's all about sorting out your concrete needs with a
                    smile. We're big on giving you a fair shake with free, no-pressure
                    quotes and payment options that won’t make your wallet weep, all part
                    of our promise to be the friendliest{" "}
                    <a
                        href="https://www.jupiterconcrete.net/"
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        concrete contractor
                    </a>{" "}
                    company on the block.
                </Text>

                <Stack
                    direction={{
                        base: "column",
                        lg: "row",
                    }}
                    spacing={{
                        base: "12",
                        lg: "16",
                    }}
                    flex="1"
                >
                    <Box as="main" role="main" width="full" bg="bg.accent.default">
                        <Stack spacing={3}>
                            <Heading as="h2" size={"md"} pb="2" pt="5">
                                Tailor-Made Concrete Services Just for Pompano Beach
                            </Heading>
                            <UnorderedList>
                                <ListItem>
                                    <Link href={"/decorative-concrete/"}>Decorative Concrete</Link>
                                    : Spice up your space with our snazzy designs, starring our
                                    famous stamped concrete that's sure to turn heads. Yep, that's
                                    our bread and butter as concrete contractors in florida.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/driveways-patios/"}>Driveways and Patios</Link>:
                                    Trust our team to jazz up your outdoors with top-notch
                                    concrete work that's both pretty and practical.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/residential-concrete/"}>
                                        Residential Concrete
                                    </Link>
                                    : As the go-to concrete contractors, we're all about giving
                                    your home a facelift, from rock-solid foundations to swanky
                                    stamped concrete paths.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/commercial-concrete/"}>Commercial Concrete</Link>
                                    : Lean on our know-how for tough, trendy concrete fixes
                                    that'll make your business space shine.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/concrete-repair/"}>Concrete Repair</Link>: Our
                                    crew knows their way around <a href={'https://roswellconcretecontractors.com/'}
                                                                   target={"_blank"}
                                                                   rel={'noreferrer'}>concrete repair</a>, making old
                                    concrete look new and strong.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/concrete-installation/"}>
                                        Concrete Installation
                                    </Link>
                                    : Get ready for a smooth, tailored installation process, all
                                    thanks to your friendly neighborhood concrete contractors.
                                </ListItem>
                                <ListItem>
                                    <Link href={"/stamped-concrete/"}>Stamped Concrete</Link>:
                                    Check out our stamped concrete offerings, the perfect blend of
                                    elegance and value, straight from our expert concrete
                                    contractors.
                                </ListItem>
                            </UnorderedList>
                        </Stack>
                    </Box>
                    <Box
                        as="aside"
                        role="complementary"
                        bg="bg.accent.default"
                        width={{
                            base: "full",
                            lg: "xl",
                        }}
                        alignSelf="center"
                        position={{
                            base: "unset",
                        }}
                        top="36"
                    >
                        <Stack spacing={6}>
                            <Image
                                src={concrete10}
                                alt={`concrete contractor pompano beach`}
                            />
                        </Stack>
                    </Box>
                </Stack>

                <Heading as="h2" size={"md"} pb="2" pt="3">
                    Pompano Concrete: Where Quality Meets Affordability
                </Heading>
                <Text>
                    At Pompano Concrete, we're on a mission to be the ultimate solution as contractors in pompano beach
                    fl. We redefine the perception of top-notch concrete contracting. We believe that quality service
                    shouldn't come at the cost of an arm and a leg. With us, you can
                    expect nothing but upfront and honest pricing.
                    <br/>
                    Our philosophy revolves around blending high-quality service with
                    prices that bring a smile to your face. We are committed to delivering
                    exceptional value for your investment as your chosen concrete
                    contractors. Our goal is to provide a seamless experience where you
                    receive the best in concrete services without breaking the bank. At
                    Pompano Concrete, it's not just about completing a project; it's about
                    creating a partnership that prioritizes your satisfaction and peace of
                    mind.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Why Pompano Concrete? Because We're Pompano Beach's Concrete Rockstars
                </Heading>
                <UnorderedList>
                    <ListItem>
                        Speedy, dependable service? That's our signature as your friendly
                        <a href={"https://affordableconcreteservicespeoria.com/"} target={"_blank"} rel={"noreferrer"}>concrete
                            contractors</a>.
                    </ListItem>
                    <ListItem>
                        From custom stamped concrete to all-around concrete work, our range
                        is as broad as our commitment to quality.
                    </ListItem>
                    <ListItem>
                        Deeply rooted in Pompano Beach, we're the concrete contractor who
                        knows what makes this place tick.
                    </ListItem>
                    <ListItem>
                        Prices that make sense and service that wows - that's the Pompano
                        Concrete way.
                    </ListItem>
                    <ListItem>
                        Big or small, we're ready for your project, showcasing our zest as
                        all-around concrete contractors.
                    </ListItem>
                </UnorderedList>
                <Text>
                    At Pompano Concrete, we're more than just your average concrete
                    contractor; we're a part of the Pompano Beach family, committed to
                    sprucing up our community, one project at a time.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Kickstart Your Concrete Adventure with Pompano Concrete
                </Heading>
                <Text>
                    Ready for your concrete project in Pompano Beach, FL? Look no further
                    than Pompano Concrete, your friendly neighborhood{" "}
                    <a
                        href={"https://www.deerparkconcrete.com/"}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        concrete contractor
                    </a>
                    . Drop us a line for a free estimate and find out why we're the talk
                    of the town in concrete contracting. We're all about convenience,
                    accepting all kinds of payment methods. Thanks for thinking of Pompano
                    Concrete – we can't wait to team up with you on your next concrete
                    escapade.
                </Text>
                <Button
                    as={"a"}
                    href={"tel:7542012329"}
                    bg={"#17b644"}
                    fontSize={{base: "20px", md: "23px"}}
                    color={"white"}
                    fontWeight={"bold"}
                    _hover={{bg: "#06c03b", color: "white", cursor: "pointer"}}
                    p={"16px 30px"}
                    borderRadius={0}
                    mt={"2rem"}
                    lineHeight={"26px"}
                    minH={"66px"}
                    width={"100%"}
                    maxW={"400px"}
                >
                    <i className="fa fa-phone" aria-hidden="true"/>
                    &nbsp;&nbsp;Contact Us Today!
                </Button>
            </Box>,
        ],
        additionalContent: <>
            <Container maxW="container.xl">
                <Box as="section" pt={10}>
                    <Text mb={4}>
                        Nestled within the vibrant community of Pompano Beach, Pompano Concrete is your trusted partner
                        for all things concrete. We specialize in bringing your vision to life with our array of
                        services, including <a href={"https://www.montclairretainingwall.com"} target={"_blank"}
                                               rel={"noreferrer"}>retaining wall</a> installation, the ever-popular
                        stamped concrete driveway, and pool deck designs that are
                        both stylish and enduring.
                    </Text>
                </Box>

                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Craftsmanship in Concrete
                    </Heading>
                    <Text mb={4}>
                        With our finger on the pulse of the latest trends, such as modern stamped concrete, we merge
                        quality with the aesthetic charm. Our seasoned team applies meticulous attention to detail to
                        every project, from rustic wood stamped concrete patios that echo natural beauty, to the
                        practical elegance of stamped concrete walkways.
                    </Text>
                </Box>

                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Comprehensive Concrete Services
                    </Heading>
                    <Text mb={4}>
                        At Pompano Concrete, our services are as varied as they are reliable, ensuring that whether it's
                        a stamped concrete driveway cost estimate or an intricate decorative concrete resurfacing
                        project, we deliver with excellence:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong><Link href="/decorative-concrete/">Decorative Concrete</Link></strong>: Elevate your
                            exteriors with our decorative flair, including stamped concrete around pools, bringing a
                            touch of sophistication to every corner.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/driveways-patios/">Driveways and Patios</Link></strong>: Our driveways
                            and patios are more than just surfaces; they're the welcome mat to your home, crafted with
                            care and designed to impress.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/residential-concrete/">Residential Concrete</Link></strong>: We
                            understand the importance of a home’s foundation, and we extend this ethos to every
                            residential concrete service we offer, ensuring durability and style go hand-in-hand.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/commercial-concrete/">Commercial Concrete</Link></strong>: Our
                            commercial concrete services are the cornerstone of businesses looking for robust and
                            attractive concrete solutions.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/concrete-repair/">Concrete Repair</Link></strong>: We're not just about
                            the new; our concrete repair services are here to rejuvenate and fortify your existing
                            concrete, ensuring it stands the test of time.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/concrete-installation/">Concrete Installation</Link></strong>:
                            Experience seamless and customized concrete installation, from the first pour to the final
                            finish.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/stamped-concrete/">Stamped Concrete</Link></strong>: Dive into our
                            stamped concrete services where functionality meets elegance, perfect for that pool concrete
                            resurfacing or creating a picturesque stamped concrete walkway.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/eco-friendly-concrete/">Eco-Friendly Concrete</Link></strong>: Explore
                            our sustainable concrete solutions that blend environmental responsibility with durability.
                            Ideal for projects requiring a green touch without compromising on quality, our eco-friendly
                            concrete options are perfect for driveways, patios, and any space wishing to enhance its
                            ecological footprint.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Pompano Concrete: A Cut Above
                    </Heading>
                    <Text mb={4}>
                        Choosing Pompano Concrete means opting for a company that's synonymous with excellence. <Link
                        href={'/directories-on-the-web/'}>Find us on the web</Link> and discover
                        why our stamped concrete services and more are the talk of the town in Pompano Beach and beyond.
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Expertise:</strong> Whether it's a patio resurfacing or a complete exterior concrete
                            resurfacing, our expertise ensures top-notch results.
                        </ListItem>
                        <ListItem>
                            <strong>Quality:</strong> Our commitment to quality material and craftsmanship means your
                            concrete installations endure Florida's unique climate, from blazing sun to tropical storms.
                        </ListItem>
                        <ListItem>
                            <strong>Customer Focus:</strong> We're concrete contractors with a heart, dedicated to
                            understanding and fulfilling your specific needs.
                        </ListItem>
                        <ListItem>
                            <strong>Eco-Friendly:</strong> Our approach to concrete work is as responsible to the
                            environment as it is to your project's aesthetic and functional needs.
                        </ListItem>
                        <ListItem>
                            <strong>Local Savvy:</strong> Our deep familiarity with Pompano Beach's environment ensures
                            our concrete solutions are perfectly matched to local needs.
                        </ListItem>
                        <ListItem>
                            <strong>Premium Materials:</strong> We choose the finest materials for durability and
                            aesthetic appeal, ensuring your investment lasts.
                        </ListItem>
                        <ListItem>
                            <strong>Client-Focused Service:</strong> We prioritize your satisfaction above all,
                            customizing our services to meet your specific requirements.
                        </ListItem>
                        <ListItem>
                            <strong>Sustainable Practices:</strong> Our dedication to sustainability is evident in our
                            use of eco-friendly materials and methods.
                        </ListItem>
                        <ListItem>
                            <strong>Full-Service Offerings:</strong> From consultation to aftercare, we provide a
                            comprehensive suite of services to address all your concrete needs.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Our Approach - Paving the Way to Excellence
                    </Heading>
                    <Text mb={4}>
                        At Pompano Concrete, our process is meticulously designed to ensure a smooth and rewarding
                        experience for our clients:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Consultation:</strong> We begin with a thorough discussion to comprehend your needs
                            and evaluate your property’s concrete requirements.
                        </ListItem>
                        <ListItem>
                            <strong>Personalized Planning:</strong> We tailor each project to suit your preferences,
                            ensuring optimal results.
                        </ListItem>
                        <ListItem>
                            <strong>Expert Installation:</strong> Our skilled professionals guarantee a neat, efficient,
                            and non-intrusive installation process.
                        </ListItem>
                        <ListItem>
                            <strong>Assured Quality:</strong> Post-installation, we conduct extensive checks to ensure
                            our work meets our high standards.
                        </ListItem>
                        <ListItem>
                            <strong>Continued Support:</strong> Our commitment to you extends beyond the project
                            completion, offering ongoing assistance and advice.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Frequently Asked Questions (FAQs)
                    </Heading>
                    <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                        {faqs.map(({question, answer}, index) => (
                            <Box
                                p={5}
                                // shadow="md"
                                borderWidth="1px"
                                borderRadius="lg"
                                key={index}
                            >
                                <Heading fontSize="xl">{question}</Heading>
                                <Text mt={4}>{answer}</Text>
                            </Box>
                        ))}
                    </Grid>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Begin Your Concrete Journey with Us
                    </Heading>
                    <Text mb={4}>
                        Ready to elevate your Pompano Beach property with Pompano Concrete’s unmatched services? Whether
                        enhancing aesthetics, functionality, or seeking sustainable concrete options, we're here to make
                        it happen. Contact us today to start your journey towards a more beautiful, durable, and
                        efficient property.
                    </Text>
                    <Button colorScheme="blue" size="md" mb={4} as={'a'} href={telLink}>
                        Contact Us Now
                    </Button>
                </Box>
            </Container>
        </>,
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Expert Craftsmanship:</b> Our team of{" "}
                        <a
                            href="https://www.kellerconcretecontractors.com/"
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            concrete contractors
                        </a>{" "}
                        are equipped with years of experience, ensures quality workmanship
                        for every project we undertake.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Customized Solutions:</b> We understand each project is unique.
                        That's why we offer tailored concrete solutions to meet your
                        specific needs.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Quality Materials:</b> We believe in providing durable and
                        lasting solutions. Hence, we use top-quality materials for all our
                        concrete services.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Professional and Reliable:</b> As a reputable name in Pompano
                        Beach, we strive to deliver consistent, dependable, and professional
                        services to our clients. From large projects to your{" "}
                        <a
                            href="https://www.northportconcretepro.com/"
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            concrete driveway
                        </a>{" "}
                        we are here to serve all your concrete and cement needs.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>Customer Satisfaction:</b> Our ultimate goal is the satisfaction
                        of our customers. We go above and beyond to ensure you are happy
                        with our services whether you are doing a big project or just a{" "}
                        <a
                            href="https://www.desplainesconcrete.com"
                            target="blank"
                            rel="noreferrer"
                        >
                            concrete patio
                        </a>
                        .
                    </Text>
                ),
            },
        ],
        footerHeading: "Reach Out to Pompano Concrete",
        footerText: () => (
            <>
                For expert concrete services in Pompano Beach and the surrounding areas,
                connect with us today. We're ready to assist with your concrete
                projects. <Link href={telLink}>Contact us</Link> or fill out
                our <Link href={"/contact/"}>online form</Link> for non-emergency
                inquiries. Pompano Concrete is your go-to solution for concrete
                services.
            </>
        ),
        footerText2:
            "Quality, Efficiency, and Integrity are Our Guiding Principles",
        servicesHeader:
            "Our Comprehensive Range of Concrete Services for All Your Construction and Renovation Needs",
        servicesSubtitle: () => (
            <Text color="muted" fontSize={{base: "lg", md: "xl"}}>
                Explore our <Link href={"/services"}>suite of services</Link> – from
                concrete mixing to installation, Pompano Concrete is your one-stop
                solution for all concrete and cement-related needs
            </Text>
        ),
        featuredServices: [
            {
                name: "Decorative Concrete",
                description:
                    "Transform your outdoor spaces with our decorative concrete services. From stamped concrete to acid stained, we provide a variety of eye-catching, durable designs that enhance your property's appeal.",
                icon: BsShieldFillCheck,
                path: "/decorative-concrete/",
            },
            {
                name: "Driveways & Patios",
                description:
                    "Whether you want a new concrete driveway or patio, Pompano Concrete delivers quality, durability, and style. We bring to life your visions, enhancing the usability and aesthetics of your outdoor areas.",
                icon: FaRoad,
                path: "/driveways-patios/",
            },
            {
                name: "Residential Concrete",
                description:
                    "Specializing in residential concrete services, we cater to a wide range of projects, from foundations to walkways and slabs. We ensure high-quality workmanship that stands the test of time.",
                icon: FaHome,
                path: "/residential-concrete/",
            },
            {
                name: "Commercial Concrete",
                description:
                    "Offering commercial concrete services, we handle projects of all scales, from parking lots to large-scale building foundations. We guarantee reliability, durability, and efficiency in every job.",
                icon: FaBuilding,
                path: "/commercial-concrete/",
            },
            {
                name: "Concrete Repair",
                description:
                    "Our concrete repair service addresses issues like cracking or deterioration, restoring the strength and appearance of your structures, thus extending their lifespan.",
                icon: AiFillTool,
                path: "/concrete-repair/",
            },
            {
                name: "Concrete Installation",
                description:
                    "From planning to execution, our professional concrete installation service guarantees a seamless process and a finished product that meets your expectations and conforms to industry standards.",
                icon: GiConcreteBag,
                path: "/concrete-installation/",
            },
            {
                name: "Stamped Concrete",
                description:
                    "Elevate your property's beauty with our expert stamped concrete services. Our skilled artisans create stunning stamped concrete designs for driveways, patios, and walkways that leave a lasting impression.",
                icon: FaPuzzlePiece,
                path: "/stamped-concrete/",
            },
        ],
    },
    services: {
        url: "https://www.pompanoconcrete.com/services",
        metaData: {
            title:
                "Professional Concrete Services - Pompano Concrete",
            description:
                "Reliable and high-quality concrete services in Pompano Beach, FL. Contact Pompano Concrete at 754-201-2329 for all your concrete needs.",
        },
        h1: "Professional Concrete Services in Pompano Beach, FL",
        subtitle:
            "At Pompano Concrete, we offer a wide range of concrete services, ensuring that we can meet all of your concrete needs.",
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-concrete"}>Residential Concrete:</Link>
                        </b>{" "}
                        We offer comprehensive concrete services for your home. From
                        driveways to patios, we ensure quality and durability in every
                        project.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-concrete"}>Commercial Concrete:</Link>
                        </b>{" "}
                        Whether it's a parking lot or a commercial building, we have the
                        expertise to handle all types of commercial concrete projects.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/concrete-repair"}>Concrete Repair:</Link>
                        </b>{" "}
                        Don't let cracks or damage compromise the look of your concrete. Our
                        team provides expert concrete repair services to restore the look
                        and durability of your concrete.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/concrete-installation"}>
                                Concrete Installation:
                            </Link>
                        </b>{" "}
                        Planning for a new concrete installation? Trust us for efficient and
                        professional concrete installation services.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/decorative-concrete"}>Decorative Concrete:</Link>
                        </b>{" "}
                        Add a touch of elegance to your property with our decorative
                        concrete services. We offer a wide range of designs and finishes to
                        choose from.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/driveways-patios"}>Driveways and Patios: </Link>
                        </b>
                        Enhance your outdoor spaces in Pompano Beach, Florida with our
                        stunning driveways and patios services. We deliver top-quality,
                        durable, and visually appealing solutions tailored to your style.
                        Whether you prefer the rustic appeal of a cobblestone driveway or
                        the elegant design of a tiled patio, we have the skills and
                        experience to bring your vision to life. Contact us at 475-242-8150
                        to discuss your project and receive a free quote.
                    </Text>
                ),
            },
        ],
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Pompano Concrete: Premier Concrete Services in Pompano Beach, FL
                    </Heading>
                    <Text>
                        Pompano Concrete is your premier destination for exceptional
                        concrete services in Pompano Beach, Florida. Specializing in both
                        residential and commercial projects, we're committed to providing
                        top-quality workmanship, whether it's a new construction,
                        renovation, or repair. Our dedication to excellence and customer
                        satisfaction sets us apart as a leading service provider in the
                        region.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose Pompano Concrete?
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Experienced Professionals:</b> Our team is comprised of
                                skilled professionals with extensive experience in a diverse
                                range of concrete projects, ensuring every job is executed with
                                precision and expertise.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>State-of-the-Art Techniques:</b> We use the latest in
                                concrete technology and methods to provide durable and
                                aesthetically pleasing results. From decorative concrete to
                                robust structural work, we handle it all.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Customer-Centric Service:</b> Our services are tailored to
                                meet your specific needs. We work closely with you to ensure
                                your vision is brought to life, with satisfaction as our top
                                priority.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Competitive Pricing:</b> We believe in offering quality
                                services at fair and transparent prices. Get the best value for
                                your investment with our competitive rates.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Local Pompano Beach Expertise:</b> Our deep understanding of
                                local regulations and environmental conditions in Pompano Beach,
                                FL, ensures that your project is compliant and sustainable.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Our Comprehensive Concrete Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Residential and Commercial Concrete:</b> We cater to both
                                residential and commercial clients, offering everything from
                                driveway installations to large-scale building projects.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Decorative Concrete:</b> Elevate your property's appeal with
                                our decorative concrete services, including stamped, stained,
                                and colored concrete options.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Concrete Repair and Maintenance:</b> Extend the life of your
                                concrete with our repair and maintenance services, addressing
                                issues like cracking, chipping, and wear.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Custom Concrete Solutions:</b> Our team specializes in
                                creating custom concrete designs to suit your unique
                                requirements, from functional pathways to elegant patios.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Tailored Solutions for Every Project
                    </Heading>
                    <Text>
                        At Pompano Concrete, we understand that each project is unique. We
                        offer tailored solutions to fit the specific needs of your project,
                        ensuring a perfect result that aligns with your vision and budget.
                    </Text>

                    <Heading as="h3" size="lg">
                        Dedication to Quality and Reliability
                    </Heading>
                    <Text>
                        Quality and reliability are at the core of everything we do. We are
                        committed to delivering services that not only meet but exceed your
                        expectations, ensuring your project's success and your utmost
                        satisfaction.
                    </Text>

                    <Heading as="h3" size="lg">
                        Contact Pompano Concrete Today
                    </Heading>
                    <Text>
                        For top-quality concrete services in Pompano Beach, FL, turn to
                        Pompano Concrete. Contact us today to discuss your project. We are
                        ready to bring our expertise and dedication to your next concrete
                        endeavor.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    contact: {
        url: "https://www.pompanoconcrete.com/contact",
        metaData: {
            title: "Contact Pompano Concrete: 24/7 Assistance | Call 754-201-2329",
            description:
                "Contact Pompano Concrete for reliable 24/7 assistance. We're here to provide professional concrete services when you need them most in Pompano Beach, FL.",
        },
        h1: "Get in Touch With Us",
        subtitle: () => (
            <>
                We know you have a lot of concrete companies to choose from, So on
                behalf of the entire Pompano Concrete team, thank you for choosing us.
                When you choose Pompano Concrete, you choose peace of mind. We take the
                guesswork out of concrete projects. With so many things on your plate,
                you can rest easy letting us handle the work. Our fast and friendly{" "}
                <Link href={"/"}>Pompano Concrete team</Link>is ready to help you with
                all your concrete needs. We're here for you -{" "}
                <Link href={"tel:7542012329"}>Give us a call!</Link>
            </>
        ),
        sectionHeading: "Why Choose Pompano Concrete?",
        signUpDescription:
            "If you prefer to reach out to us online, please fill out the contact form below. We will get back to you as soon as possible.",
        feedbackText:
            "Your feedback keeps us improving! If you have any questions or concerns we would love to hear from you! With you in mind, all of your feedback goes toward keeping your satisfaction a top priority.",
        closingText: (
            <Text align={"center"}>
                {" "}
                <Link href={"/contact/"}>Reach out to us</Link> for all concrete needs in
                Pompano Beach!
            </Text>
        ),
        valueProps: [
            {
                title: "Quality Materials:",
                description:
                    "We use only high-quality materials for all of our projects. Our concrete is made to last, providing you with a durable solution for your construction needs.",
            },
            {
                title: "Experienced Team:",
                description:
                    "Our team of professionals has years of experience in the industry. We know how to handle any project, big or small, with skill and precision.",
            },
            {
                title: "Excellent Customer Service:",
                description:
                    "At Pompano Concrete, customer satisfaction is our top priority. We work closely with you to ensure your project is completed to your satisfaction.",
            },
            {
                title: "Versatile Solutions:",
                description:
                    "No matter what your concrete needs are, we have a solution. From driveways to patios to commercial projects, we do it all.",
            },
            {
                title: "Our promise to you:",
                description:
                    "At Pompano Concrete it is our highest priority to make our customers' lives easier during the construction process. We strive to exceed your expectations by providing excellent customer service and are committed to delivering high-quality results on every project.",
            },
        ],
    },
    commercialConcrete: {
        url: "https://www.pompanoconcrete.com/commercial-concrete",
        metaData: {
            title: "Commercial Concrete in Pompano Beach, FL | Pompano Concrete",
            description:
                "For top-notch commercial concrete services in Pompano Beach, FL, contact Pompano Concrete at 754-201-2329. Expert support for your projects.",
        },
        h1: "Commercial Concrete Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "Pompano Concrete is your trusted partner for professional, reliable commercial concrete services, available to support all your commercial concrete requirements.",
        body: <CommercialConcrete/>,
    },
    concreteInstallation: {
        url: "https://www.pompanoconcrete.com/concrete-installation",
        metaData: {
            title: "Concrete Installation in Pompano Beach, FL | Pompano Concrete",
            description:
                "Pompano Concrete offers top-notch concrete installation services in Pompano Beach, FL. Contact us at 754-201-2329 for expert and efficient service.",
        },
        h1: "Concrete Installation Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "Choose Pompano Concrete for professional, reliable concrete installation services, ensuring your project is executed flawlessly from start to finish.",
        body: <ConcreteInstallation/>,
    },
    stampedConcrete: {
        url: "https://www.pompanoconcrete.com/stamped-concrete-installation",
        metaData: {
            title:
                "Stamped Concrete Installation in Pompano Beach, FL - Pompano Concrete",
            description:
                "Expert stamped concrete installation services in Pompano Beach, FL. Contact Pompano Concrete at 754-201-2329 for high-quality installations.",
            keywords:
                "Stamped Concrete Installation, Pompano Beach, FL, Stamped Concrete, Concrete Services",
        },
        h1: "Stamped Concrete Installation in Pompano Beach, FL",
        subtitle: "Enhance Your Space with Stamped Concrete | Pompano Concrete",
        body: <StampedConcrete/>,
    },
    concreteRepair: {
        url: "https://www.pompanoconcrete.com/concrete-repair",
        metaData: {
            title: "Concrete Repair in Pompano Beach, FL | Pompano Concrete",
            description:
                "Trust Pompano Concrete for professional concrete repair services in Pompano Beach, FL. Call 754-201-2329 for reliable solutions today!",
        },
        h1: "The Ultimate Concrete Contractor for Concrete Repair and Concrete Resurfacing Pompano Beach FL",
        subtitle:
            "Trust Pompano Concrete for reliable, professional concrete repair services, ensuring your structures are maintained and restored effectively.",
        body: <ConcreteRepairs/>,
    },
    decorativeConcrete: {
        url: "https://www.pompanoconcrete.com/decorative-concrete",
        metaData: {
            title: "Decorative Concrete in Pompano Beach, FL | Pompano Concrete",
            description:
                "Enhance your property with our aesthetically pleasing and durable decorative concrete solutions. Contact us at 754-201-2329 for more information.",
        },
        h1: "Decorative Concrete Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "Choose Pompano Concrete for creative, durable decorative concrete services, ensuring your property stands out with unique design and top-quality workmanship.",
        body: <DecorativeConcrete/>,
    },
    concreteRepairs: {
        url: "https://www.pompanoconcrete.com/concrete-repairs",
        metaData: {
            title: "Concrete Repairs in Pompano Beach, FL | Pompano Concrete",
            description:
                "Pompano Concrete offers professional concrete repair services in Pompano Beach, FL. Contact us at 754-201-2329 if your concrete structures require quick, reliable repairs.",
        },
        h1: "Concrete Repair Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "Trust Pompano Concrete for quick, professional concrete repair services, ensuring your structures regain their strength and appearance as quickly as possible.",
        body: <ConcreteRepairs/>,
    },
    residentialConcrete: {
        url: "https://www.pompanoconcrete.com/residential-concrete",
        metaData: {
            title: "Residential Concrete in Pompano Beach, FL | Pompano Concrete",
            description:
                "Pompano Concrete offers reliable and professional residential concrete services in Pompano Beach, FL. Contact us at 754-201-2329 for a free quote today.",
        },
        h1: "Residential Concrete Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "At Pompano Concrete, we offer high-quality residential concrete services, ensuring reliable solutions for all your concrete needs.",
        body: <ResidentialConcrete/>,
    },
    drivewaysPatios: {
        url: "https://www.pompanoconcrete.com/driveways-patios",
        metaData: {
            title: "Driveways & Patios in Pompano Beach, FL | Pompano Concrete",
            description:
                "Trust Pompano Concrete for professional driveway and patio construction services in Pompano Beach, FL. Call us at 754-201-2329 to get started.",
        },
        h1: "Driveway & Patio Services in Pompano Beach, FL | Pompano Concrete",
        subtitle:
            "Pompano Concrete is your trusted provider for efficient, safe driveway and patio services, available to enhance the aesthetic and functionality of your property.",
        body: <DrivewaysPatios/>,
    },
    blog: {
        url: "https://www.pompanoconcrete.com/blog",
        metaData: {
            title: "Pompano Concrete: Reliable & Professional Solutions | Blog",
            description:
                "Discover reliable concrete tips and services in Pompano Beach. Contact us for professional concrete solutions at competitive prices. Visit our blog now!",
        },
        h1: "Pompano Concrete Articles and Tips",
        h2: "Our Blog",
        heading:
            "Pompano Concrete is always available to offer you professional, efficient service. Whether you need immediate assistance or plan to start a project, our team is always just a call away. Stay in touch with us and keep coming back, we'll keep the posts coming and we'll be happy to answer any questions you may have. Pompano Concrete in Pompano Beach, FL, thanks for being with us!",
        posts: posts,
    },
    testimonials: {
        h1: "Testimonials",
        heading:
            "Discover why our clients recommend us – from concrete installation to extensive repair, Pompano Concrete is the top-rated choice for all your concrete service needs in Pompano Beach, FL",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e29026704f",
                name: "Mary R.",
                logo: undefined,
                title: "Pompano Beach, FL",
                quote:
                    '"Last year, we embarked on a home renovation project which required a complete overhaul of our driveway. We reached out to Pompano Concrete and their team took care of everything from removal of the old concrete to installation of the new driveway. Their professionalism, workmanship, and timeliness were remarkable. For any concrete needs in Pompano Beach, I will always trust Pompano Concrete."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425d",
                name: "David J.",
                logo: undefined,
                title: "Pompano Beach, FL",
                quote:
                    '"I have hired Pompano Concrete on multiple occasions for both my residential and commercial properties. They have consistently delivered high-quality work, from concrete installation to repair. I\'ve always been impressed with their expertise, commitment, and the quality of their work. They leave my properties looking impeccable every time. I highly recommend them for any concrete needs in Pompano Beach."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=b0537c",
                name: "Emily R.",
                logo: undefined,
                title: "Pompano B each, FL",
                quote:
                    '"I\'ve collaborated with Pompano Concrete on various projects and their professionalism and skill are unmatched. Their attention to detail and ability to execute complex designs is remarkable. Every project has exceeded expectations, both in aesthetics and durability. Their team is knowledgeable, friendly, and willing to go the extra mile to ensure client satisfaction. Pompano Concrete is my go-to for all concrete-related projects."',
            },
        ],
    },
    about: {
        url: "https://www.pompanoconcrete.com/about/",
        metaData: {
            title:
                "Premium Concrete Services in Pompano Beach | 754-201-2329",
            description:
                "Explore our comprehensive concrete services in Pompano Beach. From concrete pouring to repairs, our expert team is ready to meet all your concrete needs.",
        },
        h1: "Best Concrete Services in Pompano Beach, FL",
        h2: [
            "Custom Concrete Solutions: Tailored services for residential and commercial projects.",
            "Experienced Concrete Specialists: Our team is equipped with the skills and knowledge to handle a variety of concrete works.",
            "Wide Range of Services: Offering everything from driveways and sidewalks to decorative concrete.",
            "Sustainable Practices: We use eco-friendly methods and materials in our concrete services.",
            "Competitive Pricing: Providing affordable yet high-quality concrete services.",
        ],
        headingText: {
            commitment:
                "Committed to delivering top-notch concrete services, ensuring durability and quality in every project.",
            team: "Our team at Pompano Concrete comprises experienced professionals dedicated to craftsmanship and customer satisfaction in every concrete job.",
            services:
                "Pompano Concrete offers a diverse array of services including concrete pouring, repair, resurfacing, and decorative concrete work. We utilize advanced techniques and tools to ensure the longevity and aesthetic appeal of your concrete structures. Our services are ideal for both residential and commercial properties, ensuring a perfect blend of functionality and design.",
        },
        featuredServices: [
            {
                name: "Decorative Concrete",
                description:
                    "Transform your outdoor spaces with our exceptional decorative concrete services. At Pompano Concrete, we offer a range of eye-catching and durable designs, including stamped concrete and acid-stained finishes, to enhance the appeal of your property. Whether you're looking to revamp your driveway, patio, or walkway, our skilled craftsmen are dedicated to delivering transformative results that elevate the aesthetics of your outdoor areas. Choose Pompano Concrete for creative and durable decorative concrete solutions that make a lasting impression on your property.",
                icon: BsShieldFillCheck,
                path: "/decorative-concrete",
            },
            {
                name: "Driveways & Patios",
                description:
                    "Whether you're in need of a new concrete driveway or patio, Pompano Concrete is committed to delivering a blend of quality, durability, and style. Our team brings your visions to life, enhancing both the usability and aesthetics of your outdoor areas. With a focus on precision and craftsmanship, we create concrete surfaces that not only meet but exceed your expectations, ensuring a perfect balance of functionality and visual appeal. Choose Pompano Concrete for top-notch concrete solutions that transform your outdoor spaces with enduring quality and timeless style.",
                icon: FaRoad,
                path: "/driveways-patios",
            },
            {
                name: "Residential Concrete",
                description:
                    "Specializing in residential concrete services, Pompano Concrete caters to a diverse range of projects, encompassing foundations, walkways, and slabs. Our commitment is to deliver high-quality workmanship that stands the test of time. Whether you're embarking on a new construction project or renovating an existing space, our skilled team ensures precision and durability in every aspect of our residential concrete services. Trust Pompano Concrete to bring expertise and reliability to your residential concrete projects, creating lasting structures that enhance the functionality and appeal of your home.",
                icon: FaHome,
                path: "/residential-concrete",
            },
            {
                name: "Commercial Concrete",
                description:
                    "Providing top-notch commercial concrete services, Pompano Concrete undertakes projects of all scales, ranging from expansive parking lots to large-scale building foundations. Our commitment is to guarantee reliability, durability, and efficiency in every job we undertake. With a skilled team and state-of-the-art equipment, we ensure that commercial projects are executed with precision, meeting the highest industry standards. Trust Pompano Concrete for your commercial concrete needs, where our dedication to excellence ensures the success and longevity of every project, regardless of size or complexity.",
                icon: FaBuilding,
                path: "/commercial-concrete",
            },
            {
                name: "Concrete Repair",
                description:
                    "Our concrete repair service is designed to address common issues such as cracking or deterioration, effectively restoring both the strength and appearance of your structures. At Pompano Concrete, we prioritize the longevity and resilience of your concrete elements by providing expert repair solutions. Our skilled team employs effective techniques to address structural concerns, ensuring that the repaired surfaces not only regain their integrity but also maintain an appealing aesthetic. Trust Pompano Concrete for comprehensive concrete repair services that extend the lifespan of your structures and enhance their overall durability.",
                icon: AiFillTool,
                path: "/concrete-repair",
            },
            {
                name: "Concrete Installation",
                description:
                    "From meticulous planning to flawless execution, our professional concrete installation service at Pompano Concrete ensures a seamless process and a finished product that not only meets but exceeds your expectations, conforming to the highest industry standards. With a team of skilled professionals, we prioritize precision in every aspect of the installation process, whether it's for a new driveway, patio, or walkway. Trust Pompano Concrete to bring expertise and reliability to your concrete installation projects, providing results that stand the test of time and elevate the functionality and aesthetics of your property.",
                icon: GiConcreteBag,
                path: "/concrete-installation",
            },
        ],
    },
    404: {
        metaData: {
            title:
                "Page Not Found - Pompano Concrete | 754-201-2329",
            description:
                "Oops! The page you're looking for could not be found. Pompano Concrete offers professional concrete services. Contact us for reliable help.",
            siteName: "Concrete Service in Pompano Beach, FL 754-201-2329",
        },
        h1: "404 - Oh no, you found a page that's missing stuff.",
        subtitle: "Sorry, the page you are looking for does not exist.",
        p: "Whether you require concrete for a new project, repair of an existing one, or other concrete-related services, We've got you covered. ",
    },
};
