import React from 'react';

const PostFooter = () => {
    return (
        <div className="slide_navi">
            <div className="side_footer_social">
                <ul className="bottom_social">
                    {/*<li className="facebook"><a href="/"><i className="ion-social-facebook"></i></a></li>*/}
                    {/*<li className="twitter"><a href="/"><i className="ion-social-twitter"></i></a></li>*/}
                    {/*<li className="dribbble"><a href="/"><i className="ion-social-dribbble-outline"></i></a></li>*/}
                    {/*<li className="instagram"><a href="/"><i className="ion-social-instagram-outline"></i></a></li>*/}
                    {/*<li className="linkedin"><a href="/"><i className="ion-social-linkedin-outline"></i></a></li>*/}
                </ul>
            </div>
        </div>
    );
};

export default PostFooter;