import React from "react";
import {Box, Button, Heading, Spacer, Stack, Text} from "@chakra-ui/react";
import {SiteData} from "./siteData";
import {SEO} from "../Components/SEO/SEO";

export const BlogPost2 = () => {
    return (
        <Box py={{base: 0, md: 0}} mt={{base: 0, md: 0}}>
            <SEO
                title="Stunning Driveways & Patios in Pompano Beach, Florida"
                description="Transform your outdoor living space with Pompano Concrete's expert driveway and patio installations in Pompano Beach, FL. Quality and elegance in every project."
            />
            <Spacer height={{base: "2rem", md: "4rem"}}/>
            <div className="main_wrapper">
                <div className="container">
                    <Stack>
                        <Heading as="h2" size="md">
                            Elevate Your Outdoor Spaces in Pompano Beach
                        </Heading>
                        <Text>
                            Welcome to <strong>Pompano Concrete</strong>, where we specialize in creating stunning
                            driveways and patios that elevate your outdoor living space. Serving Pompano Beach, Florida,
                            our dedication to quality and precision ensures that every project we undertake enhances the
                            beauty and functionality of your property.
                        </Text>

                        <Heading as="h2" size="md">
                            Custom Design and Installation
                        </Heading>
                        <Text>
                            Your vision is our blueprint. At Pompano Concrete, we understand that a one-size-fits-all
                            approach doesn't apply to outdoor spaces. We offer custom design services tailored to your
                            specific needs and preferences, ensuring that your new driveway or patio perfectly
                            complements your home and lifestyle.
                        </Text>

                        <Heading as="h2" size="md">
                            Unmatched Quality and Craftsmanship
                        </Heading>
                        <Text>
                            Quality is at the heart of everything we do. Using only the finest materials and the latest
                            techniques, we guarantee that your driveway or patio will not only look exceptional but also
                            stand the test of time against Pompano Beach's unique climate.
                        </Text>

                        <Heading as="h2" size="md">
                            A Smooth and Efficient Process
                        </Heading>
                        <Text>
                            We value your time and peace of mind. Our team of experts ensures a smooth, efficient
                            process from the initial design to the final installation, minimizing disruption to your
                            daily life. We're committed to delivering your project on time and within budget, with
                            meticulous attention to detail.
                        </Text>

                        <Heading as="h2" size="md">
                            Why Choose Pompano Concrete?
                        </Heading>
                        <Text>
                            Pompano Concrete stands out for its commitment to customer satisfaction, exceptional
                            craftsmanship, and dedication to bringing your outdoor space dreams to life. We pride
                            ourselves on our professionalism, reliability, and the lasting relationships we build with
                            our clients in Pompano Beach and beyond.
                        </Text>

                        <Heading as="h2" size="md">
                            Comprehensive Care from Start to Finish
                        </Heading>
                        <Text>
                            Our job doesn't end with installation. Pompano Concrete provides comprehensive care,
                            including maintenance and repair services, to ensure your driveway or patio remains in
                            pristine condition for years to come. Trust us to be your partner in all things concrete,
                            every step of the way.
                        </Text>

                        <Heading as="h2" size="md">
                            Eco-Friendly Solutions for a Sustainable Future
                        </Heading>
                        <Text>
                            At Pompano Concrete, we're committed to sustainability. Our eco-friendly materials and
                            methods ensure a lower environmental impact without sacrificing quality or durability.
                            Choose us for a greener, more sustainable approach to your outdoor projects.
                        </Text>

                        <Heading as="h2" size="md">
                            Let's Get Started on Your Dream Project
                        </Heading>
                        <Text>
                            Are you ready to transform your outdoor space with a stunning new driveway or patio? Contact
                            Pompano Concrete today to begin your journey toward a more beautiful and functional property
                            in Pompano Beach, Florida.
                        </Text>

                        <Button
                            as={"a"}
                            href={SiteData.telLink}
                            colorScheme="blue"
                            size="lg"
                            mb={6}
                            _hover={{color: "white"}}
                        >
                            Contact Us Today
                        </Button>
                    </Stack>
                </div>
            </div>
        </Box>
    )
}