import React, {useEffect, useState} from "react";
import useBlogPosts from "../Hooks/useBlogPosts";
import {useLocation} from "react-router-dom";
import BlogDetails from "../Pages/Blogs/BlogDetails";
import {SEO} from "../Components/SEO/SEO";

export const BlogPost = () => {
    const [activePost, setActivePost] = useState({});
    const {posts} = useBlogPosts()
    const {pathname} = useLocation()
    useEffect(() => {
        setActivePost(posts.find((post) => pathname.includes(post.slug)))
    }, [posts, pathname, setActivePost])
    if (!activePost) return <></>
    return (
      <>
          <SEO/>
          <BlogDetails post={activePost}/>
      </>
    )
}