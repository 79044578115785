import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const DecorativeConcrete = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Enhance Your Property with Decorative Concrete in Pompano Beach, FL</Heading>
                <Text>Pompano Concrete is a leader in offering decorative concrete services in Pompano Beach, FL. We
                    specialize in transforming ordinary concrete into stunning, artistic surfaces that enhance the
                    beauty and value of your property. Our team of skilled professionals is adept at creating a wide
                    range of decorative concrete finishes to suit any aesthetic preference.</Text>
                <Text>From vibrant colors and intricate patterns to elegant textures, our decorative concrete services
                    are ideal for personalizing driveways, patios, walkways, and more. We combine artistic craftsmanship
                    with durable materials to create surfaces that are not only visually appealing but also long-lasting
                    and easy to maintain.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Customized Design Options:</b> Our decorative concrete services offer a multitude of
                        design choices, including stamped patterns, acid staining, polished finishes, and colored
                        concrete, providing endless possibilities for customization.</ListItem>
                    <ListItem><b>Expert Craftsmanship:</b> Our team brings precision, creativity, and attention to
                        detail to every project, ensuring that the final product is a true reflection of your personal
                        style and complements your property’s overall look.</ListItem>
                    <ListItem><b>Durable and Low Maintenance:</b> Decorative concrete is not just about aesthetics; it
                        is incredibly durable and requires minimal upkeep, making it a practical choice for both
                        residential and commercial applications.</ListItem>
                    <ListItem><b>Enhanced Curb Appeal:</b> A decorative concrete surface can significantly boost the
                        curb appeal and value of your property, making it stand out in the neighborhood.</ListItem>
                    <ListItem><b>Environmentally Friendly Options:</b> We are committed to sustainability, offering
                        eco-friendly decorative concrete options that reduce environmental impact while still providing
                        superior quality and style.</ListItem>
                    <ListItem><b>Competitive Pricing:</b> We offer high-quality decorative concrete services at
                        competitive prices, ensuring you receive excellent value without compromising on
                        quality.</ListItem>
                </UnorderedList>
                <Text>
                    Choose Pompano Concrete for your decorative concrete needs in Pompano Beach, FL. Our expertise in
                    creating beautiful, functional, and durable concrete surfaces will transform your property into a
                    work of art.
                </Text>
            </Stack>
        </Box>
    )
}