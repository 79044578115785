import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import TopFeatures from '../Components/TopFeatures';
import Banner from '../Containers/Banner';
import {SiteData} from "../Constants/siteData";
import {SEO} from "../Components/SEO/SEO";
import {Box, Stack, Text} from "@chakra-ui/react";
import ContactFormSection from "../Components/ContactFormSection/ContactFormSection";

const Careers = () => {
    return (
        <>
            <SEO title={SiteData.careers.metaData.title} description={SiteData.careers.metaData.description}/>
            <PageHeader header={SiteData.careers.h1} subtitle={SiteData.careers.h2[0]} />
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <div className="main_wrapper">
                        <div className="container">
                            <Stack spacing={3}>
                                {SiteData.careers.content.map((p) => {
                                    return <Text>{p}</Text>
                                })}
                            </Stack>
                        </div>
                    </div>
                </Box>
                <ContactFormSection data={[{
                    id: '1',
                    iconClass: 'ion-ios-telephone-outline',
                    infoBody01: 'Call us Today!',
                    infoBody02: SiteData.phoneNumber,
                    link: SiteData.telLink,
                    heading: 'Reach out to us',
                    cta: 'Provide some information and we\'ll reach out to you soon!'
                }]}/>
                <Banner
                    title={SiteData.contact.closingText}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />
                {/*<Testimonial01 pb='pd_btom_110' />*/}
                <TopFeatures />
            </div>
        </>
    );
};

export default Careers;