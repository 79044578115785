import React from 'react';
import '../../assets/css/style.css';
import './ErrorPage.css';
import {SiteData} from "../../Constants/siteData";
import {SEO} from "../../Components/SEO/SEO";

const ErrorPage = () => {
    return (
        <>
            <SEO
                title={SiteData["404"].metaData.title}
                type='website'
                description={SiteData["404"].metaData.description}
                siteName={SiteData["404"].metaData.siteName}
                keywords={SiteData.keywords}/>
            <main className="page-wrapper">
                <section className="error-page">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-10 offset-lg-1">
                                <div className="error-content text-center">
                                    <div className="heading">404</div>
                                    <h1 className='py-3'>{SiteData["404"].h1}</h1>
                                    <p className='pb-3'>{SiteData["404"].p}</p>

                                    <div className="btn_group">
                                        <a href='/' className="button">Back To Home Page</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ErrorPage;