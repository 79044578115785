import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SEO} from "../Components/SEO/SEO";
import {Box, Link, List, ListItem} from "@chakra-ui/react";

const urls = [
    "https://www.manta.com/c/m1w1b6v/pompano-concrete-professionals",
    "https://www.hotfrog.com/company/66075d94ee83d936cf0d3d1e48d8078d",
    "https://www.n49.com/biz/5788290/pompano-concrete-professionals/",
    "https://fl-miami.cataloxy.us/firms/pompanoconcrete.com.htm",
    "https://www.callupcontact.com/b/businessprofile/Pompano_Concrete_Professionals/8854476",
    "https://www.zeemaps.com/map?group=4877974&add=1",
    "http://www.askmap.net/location/6795044/united-states/pompano-concrete-professionals",
    "https://www.yplocal.com/construction-renovation/pompano-concrete-professionals",
    "https://www.sbnation.com/users/pompanoconcrete",
    "https://www.adlocalpages.com/general-contractors-1/pompano-concrete-professionals",
    "https://www.nextbizthing.com/construction-20-contractors/pompano-concrete-professionals",
    "https://www.freelistingusa.com/listings/pompano-concrete-professionals",
    "http://www.travelful.net/location/5346938/united-states/pompano-concrete-professionals",
    "http://www.place123.net/place/pompano-concrete-professionals-.-united-states",
    "https://teleadreson.com/pompano-concrete-professionals,-kfORAJHzkQA.html",
    "https://www.cgmimm.com/general-contractor/pompano-concrete-professionals",
    "http://where2go.com/binn/b_search.w2g?function=detail&type=power&listing_no=2125298&_UserReference=7F0000014651DA2B75DB199DBD6D65967157",
    "https://trueen.com/business/listing/pompano-concrete-professionals/388980",
    "https://smallbusinessusa.com/listing/pompanoconcrete.html",
    "https://www.iformative.com/product/concrete-p2393324.html",
    "https://parkbench.com/directory/pompano-concrete-professionals",
    "https://www.bizbangboom.com/real-estate-20-construction/pompano-concrete-professionals",
    "https://www.nextbizmaker.com/%EF%BB%BF%EF%BB%BFreal-estate-construction/pompano-concrete-professionals",
    "https://www.announceamerica.com/home-and-garden/pompano-concrete-professionals",
    "https://www.bizmakersamerica.org/%EF%BB%BF%EF%BB%BF%EF%BB%BFreal-estate-construction/pompano-concrete-professionals",
    "https://www.youbiz.com/listing/pompano-concrete-professionals.html",
    "https://www.bizmaker.org/%EF%BB%BFreal-estate-construction/pompano-concrete-professionals",
    "https://www.biztobiz.org/%EF%BB%BFreal-estate-construction/pompano-concrete-professionals",
    "https://www.homify.com/professionals/9573174/pompano-concrete-professionals",
    "https://www.earthmom.org/contractors/pompano-concrete-professionals",
    "https://www.linkcentre.com/profile/pompanoconcrete/",
    "https://www.cleansway.com/business/pompano-concrete-professionals",
    "https://www.b2bco.com/pompanoconcrete",
    "https://www.consultsdirect.com/consultant/pompano-concrete-professionals",
    "https://www.finditangeles.com/services/pompano-concrete-professionals",
    "https://flokii.com/businesses/view/118399/pompano-concrete-professionals",
    "https://i.imgur.com/5N1MrgT.png",
    "https://www.anibookmark.com/business/pompano-concrete-professionals-bs182819.html",
    "https://www.ilistbusiness.com/listing/pompano-concrete-professionals/",
    "https://serverfault.com/users/1069095/pompano-concrete-professionals?tab=profile",
    "https://www.preferredprofessionals.com/construction-contractors/pompano-concrete-professionals",
    "https://superuser.com/users/1877652/pompano-concrete-professionals?tab=profile",
    "https://411freedirectory.com/listing/pompano-concrete-professionals-592468",
    "https://activdirectory.net/listing/pompano-concrete-professionals-804066",
    "https://www.thelululist.com/brown-and-black-owned-home-services/pompano-concrete-professionals",
    "https://directory6.org/listing/pompano-concrete-professionals-646327",
    "https://stackoverflow.com/users/23194505/pompano-concrete-professionals?tab=profile",
    "https://www.directory2020.com/home-improvement-tools/pompano-concrete-professionals",
    "http://www.click4homeservices.com/masonconcrete-service/pompano-concrete-professionals",
    "https://www.ailoq.com/pompano-concrete-professionals",
    "https://bizbookusa.com/listing/pompano-concrete-professionals/",
    "https://askubuntu.com/users/1757849/pompano-concrete-professionals?tab=profile",
    "https://www.getlisteduae.com/listings/pompano-concrete-professionals",
    "https://www.localstar.org/pompano-concrete-professionals",
    "https://mathoverflow.net/users/520087/pompano-concrete-professionals?tab=profile",
    "https://www.webwiki.com/pompanoconcrete.com",
    "https://cally.com/event/index/fgw8gikdri7nf36g",
    "https://www.bunnydirectories.com/local-deals/pompano-concrete-professionals",
    "https://flipboard.com/@pompanoconc21c7/pompano-concrete-professionals-majogb00y",
    "https://www.cakeresume.com/me/pompanoconcrete",
    "https://www.digitalbusinessdirectory.online/pompano-concrete-professionals",
    "https://www.bestincom.com/contractors/pompano-concrete-professionals",
    "https://www.dennisdemo.com/home-services-1/pompano-concrete-professionals",
    "http://www.countrypwr.com/pompano-concrete-professionals",
    "https://www.osogbo.com/property-construction-and-contractors/pompano-concrete-professionals",
    "https://www.bpublic.com/home-services/pompano-concrete-professionals",
    "https://local.gocommercially.com/home-services/pompano-concrete-professionals",
    "http://www.247globalbusinesssolutions.com/home-services/pompano-concrete-professionals",
    "https://www.pinterest.com/pompanoconcrete/",
    "https://buynow-us.com/details.php?id=636191&key=fd2d5ab8efbb350ff041d30706c379d8",
    "https://medium.com/@pompanoconcrete",
    "http://prsync.com/pompano-concrete-professionals/",
    "https://www.tripadvisor.com/Profile/pompanoconcrete",
    "https://thelocal.directory/business-directory/pompano-concrete-professionals/",
    "https://www.quora.com/profile/Pompano-Concrete-Professionals",
    "https://www.insertbiz.com/listing/pompano-concrete-professionals/",
    "https://www.localhomeservicepros.com/concrete-brick-stone/pompano-concrete-professionals",
    "https://meta.stackexchange.com/users/1453395/pompano-concrete-professionals?tab=profile",
    "https://www.addonbiz.com/listing/pompano-concrete-professionals/",
    "https://penzu.com/p/b35ce102d3a69241",
    "https://www.skillshare.com/en/profile/Skillshare-Member/568616911",
    "https://www.fixerhub.com/home-service/pompano-concrete-professionals",
    "https://list.ly/list/9MwN-pompano-concrete-professionals?make_list_mode=true",
    "https://www.topgoogle.com/listing/pompano-concrete-professionals/",
    "https://www.homemavenmember.com/local-businesses/pompano-concrete-professionals",
    "https://speakerdeck.com/pompanoconcrete",
    "https://pompanocon1.livejournal.com/profile",
    "https://soundcloud.com/pompanoconcrete",
    "https://www.localbusinesslisting.org/pompano-concrete-professionals",
    "https://webforcompany.com/pompano-concrete-professionals/",
    "https://www.gravitysplash.com/b/pompano-concrete-professionals/",
    "https://www.meetyourmarkets.com/pompano-concrete-professionals",
    "https://www.findabusinesspro.com/general-business-1/pompano-concrete-professionals",
    "https://www.techdirectory.io/home-services/pompano-concrete-professionals",
    "https://helpsellmyfsbo.com/pompano-concrete-professionals",
    "https://www.boisemeridian.com/top-level-category/pompano-concrete-professionals",
    "https://www.detroitbusinesscenter.com/construction/pompano-concrete-professionals",
    "https://www.buffalosbest.com/concrete-brick-stone/pompano-concrete-professionals",
    "https://www.gunspace.net/pompano-concrete-professionals",
    "https://www.rstm.com/home-services-1/pompano-concrete-professionals",
    "https://www.myadbay.com/building-construction/pompano-concrete-professionals",
    "https://www.frontofthebeat.com/professional-development/pompano-concrete-professionals",
    "http://www.pagebook.ws/pompano-concrete-professionals"
]
const DirectoriesOnTheWeb = () => {
    return (
        <>
            <SEO title={'Pompano Concrete - Professional Concrete Services'} description={'Find Pompano Concrete on the web for all your concrete needs. Contact us at (754) 201-2329 for top-notch concrete services in Pompano Beach, FL.'}/>
            <PageHeader header={"Find us on the web!"}/>
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <List spacing={3} textAlign={'center'}>
                        {urls.map((url, index) => (
                            <ListItem key={index}>
                                <Link href={url} isExternal color="blue.500" target={"_blank"} rel={"noreferrer"}>
                                    {url}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </>
    );
};

export default DirectoriesOnTheWeb;