import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const ResidentialConcrete = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Expert Residential Concrete Services in Pompano Beach, FL</Heading>
                <Text>Pompano Concrete is a leading provider of residential concrete services in Pompano Beach, FL. We
                    specialize in delivering a wide range of high-quality concrete solutions tailored to enhance the
                    beauty and functionality of your home. From new installations to repairs and renovations, our team
                    is equipped to handle all your residential concrete needs with unmatched expertise and
                    precision.</Text>
                <Text>Whether you are looking to install a new concrete driveway, create a custom patio, or need
                    concrete repairs, we bring the same level of dedication and craftsmanship to every project. Our goal
                    is to transform your vision into a reality, ensuring that every aspect of the job meets your
                    expectations and complements your home's aesthetic.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Comprehensive Concrete Solutions:</b> We offer a variety of residential concrete
                        services including driveways, patios, walkways, pool decks, and more, all designed to meet your
                        specific needs and preferences.</ListItem>
                    <ListItem><b>Quality Materials and Craftsmanship:</b> Our commitment to using only the best
                        materials, combined with our skilled craftsmanship, ensures that your concrete installations are
                        not only beautiful but also durable and long-lasting.</ListItem>
                    <ListItem><b>Customized Designs:</b> We provide personalized design services to create unique
                        concrete features that reflect your style and enhance your property’s curb appeal.</ListItem>
                    <ListItem><b>Efficient and Reliable Service:</b> Our team works efficiently to complete your project
                        on time and with minimal disruption, while ensuring the highest standards of quality and
                        service.</ListItem>
                    <ListItem><b>Professional and Experienced Team:</b> With years of experience in residential
                        concrete, our professionals have the expertise to advise on the best solutions and execute your
                        project to perfection.</ListItem>
                    <ListItem><b>Transparent Pricing:</b> We believe in honest and clear communication regarding
                        pricing, providing you with detailed estimates upfront, with no hidden fees or
                        surprises.</ListItem>
                </UnorderedList>
                <Text>
                    At Pompano Concrete, we are dedicated to providing superior residential concrete services in Pompano
                    Beach, FL. Contact us today to discuss your project and discover how we can help enhance your home
                    with our concrete expertise.
                </Text>
            </Stack>
        </Box>
    )
}