import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const CommercialConcrete = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Top-Quality Commercial Concrete Services in Pompano Beach, FL</Heading>
                <Text>Pompano Concrete is proud to offer superior commercial concrete services in Pompano Beach, FL. Our
                    team is dedicated to providing high-quality, durable, and aesthetically pleasing concrete solutions
                    for all types of commercial projects. We understand the unique challenges of commercial concrete
                    work and are equipped to handle projects of any scale, ensuring timely completion and exceptional
                    results.</Text>
                <Text>From large commercial complexes to small business renovations, our comprehensive services are
                    designed to meet the diverse needs of our clients. We combine industry-leading expertise with
                    state-of-the-art equipment to deliver concrete solutions that not only meet but exceed
                    expectations.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Customized Concrete Solutions:</b> We specialize in creating tailored concrete designs
                        that suit your specific project requirements, ensuring a perfect match for your commercial
                        space’s aesthetic and functional needs.</ListItem>
                    <ListItem><b>Experienced and Skilled Team:</b> Our team of experienced professionals is highly
                        skilled in commercial concrete applications, ensuring that every project is completed with
                        precision and attention to detail.</ListItem>
                    <ListItem><b>Advanced Techniques and Materials:</b> Utilizing the latest in concrete technology and
                        high-quality materials, we ensure the longevity and durability of our commercial concrete
                        projects.</ListItem>
                    <ListItem><b>Efficient Project Management:</b> We manage each project efficiently, ensuring that it
                        is completed on time and within budget, without compromising on quality or safety.</ListItem>
                    <ListItem><b>Wide Range of Services:</b> Our services include foundations, flooring, decorative
                        concrete, parking lots, and much more, making us a one-stop solution for all your commercial
                        concrete needs.</ListItem>
                    <ListItem><b>Transparent Pricing:</b> We believe in clear and honest communication regarding
                        pricing. Our clients receive detailed estimates, with no hidden costs, ensuring transparency and
                        trust.</ListItem>
                </UnorderedList>
                <Text>
                    Choose Pompano Concrete for your commercial concrete projects in Pompano Beach, FL. We are committed
                    to delivering excellence and ensuring your satisfaction with every aspect of our service.
                </Text>
            </Stack>
        </Box>
    )
}