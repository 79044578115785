import CommunicationCard from "../ContactElements/CommunicationCard";
import {Box, Heading, Text} from "@chakra-ui/react";
import React from "react";

const ContactFormSection = ({data}) => {
    return (
        <div className="contact_inner">
            <Box px={{base: 3, md: 0}} pt={{base: 3, md: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-5">
                            <div className="keepintouch_3">
                                {
                                    data.map(data => <CommunicationCard
                                        key={data.id}
                                        data={data}
                                    />)
                                }
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 offset-lg-1">
                            <div className="contact_us_1">
                                <div className="section_header">
                                    <Heading as={'span'} className="section_sub_title">{data[0].heading}</Heading>
                                    <Text
                                        color="white">{data[0].cta}</Text>
                                </div>
                                <form name="contact" method="POST" action={"/?index"} data-netlify="true" id={'pompano-contact-form-mid-section'}>
                                    <input type="hidden" name="form-name" value="contact"/>
                                    <div className="form-container">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <input id="name" type="text" name="name"
                                                           className="form-control"
                                                           placeholder="Your Name*" required=""/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input id="email" type="email" name="email"
                                                           className="form-control"
                                                           placeholder="Email Address*" required=""/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <input id="phone" type="text" name="phone"
                                                           className="form-control"
                                                           placeholder="Phone Number"/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-12">
                                                <div className="form-group">
                                                        <textarea name="comment" id='comment' className="form-control"
                                                                  placeholder="Message Here*" required=""></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <input className="button"
                                                           style={{color: '#1A202C', fontWeight: 'bold'}} type="submit"
                                                           value="Submit"
                                                           name="submit"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default ContactFormSection