import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const ConcreteInstallation = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Professional Concrete Installation Services in Pompano Beach, FL</Heading>
                <Text>Pompano Concrete specializes in providing professional concrete installation services in Pompano
                    Beach, FL. Our team is committed to delivering high-quality, durable, and precisely installed
                    concrete for a variety of applications. Whether it's for new construction or renovating existing
                    structures, we bring the highest level of craftsmanship and attention to detail to every
                    project.</Text>
                <Text>Our concrete installation services are tailored to meet the unique needs of each client, ensuring
                    a seamless and successful completion of your project. We pride ourselves on using the best materials
                    and state-of-the-art techniques to deliver exceptional results.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Expertise in Diverse Applications:</b> We have extensive experience in all types of
                        concrete installations, including driveways, patios, walkways, and structural foundations,
                        ensuring versatility and proficiency in our work.</ListItem>
                    <ListItem><b>Advanced Installation Techniques:</b> Utilizing the latest methods and technology, we
                        ensure that each concrete installation is not only aesthetically pleasing but also structurally
                        sound and long-lasting.</ListItem>
                    <ListItem><b>Custom Design and Planning:</b> Our team works closely with you to plan and design your
                        project, ensuring that the final product aligns perfectly with your vision and functional
                        requirements.</ListItem>
                    <ListItem><b>Quality Materials for Durability:</b> We use only high-quality concrete materials,
                        ensuring that your installation withstands the test of time and the elements, particularly in
                        the Pompano Beach climate.</ListItem>
                    <ListItem><b>Efficient and Timely Execution:</b> Our efficient project management ensures that your
                        concrete installation is completed on time, with minimal disruption to your daily routine or
                        business operations.</ListItem>
                    <ListItem><b>Transparent and Competitive Pricing:</b> We offer clear, upfront pricing with no hidden
                        fees, providing you with a detailed estimate that reflects our commitment to fairness and value.</ListItem>
                </UnorderedList>
                <Text>
                    For top-tier concrete installation services in Pompano Beach, FL, turn to Pompano Concrete. Our
                    dedication to quality, efficiency, and customer satisfaction makes us the ideal choice for your next
                    project.
                </Text>
            </Stack>
        </Box>
    )
}