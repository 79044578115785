import {Button, Drawer, DrawerBody, DrawerContent, Stack, useDisclosure} from '@chakra-ui/react'
import {DocumentCollapse} from './DocumentCollapse'
import {ToggleButton} from './ToggleButton'
import {SiteData} from "../../../Constants/siteData";
import React from "react";

export const MobileDrawer = () => {
    const {isOpen, onToggle, onClose} = useDisclosure()
    return (
        <>
            <ToggleButton
                isOpen={isOpen}
                onClick={onToggle}
                aria-label="Open menu"
                display={{
                    base: 'inline-flex',
                    lg: 'none',
                }}
            />
            <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerBody mt="72px" p="4">
                        <Stack spacing="1">
                            {SiteData.navLinks.map(({title, href, hideFromTopNav}) => {
                                if (hideFromTopNav) {
                                    return null
                                }
                                if (title === 'Services') {
                                    return (
                                        <DocumentCollapse items={SiteData.homepage.featuredServices} title={title} key={title}/>
                                    )
                                }
                                return (
                                    <Button size="lg" variant="tertiary" justifyContent="start" as={'a'} key={title}
                                            href={href}>{title}</Button>
                                )
                            })}

                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
