import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function ResidentialConcrete() {
    return (
        <PageWrapper>
            <SEO title={SiteData.residentialConcrete.metaData.title} description={SiteData.residentialConcrete.metaData.description}/>
            <PageHeader header={SiteData.residentialConcrete.h1} subtitle={SiteData.residentialConcrete.subtitle}/>
            <ServicePageTemplate body={SiteData.residentialConcrete.body}/>
        </PageWrapper>
    )
}
