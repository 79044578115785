import React from 'react';
// import MobileNav from './MobileNav/MobileNav';
// import {SiteData} from "../../Constants/siteData";
// import {Box, Link, Text} from "@chakra-ui/react";
// import {useLocation} from "react-router-dom";
import {NavBar} from "./NavbarWithPopover/App";
import {Box, Button, Container, Stack, Text} from "@chakra-ui/react";
import {BsClockHistory} from "react-icons/bs";
import {SiteData} from "../../Constants/siteData";

const Header = () => {
    return (
        <>
            <Box borderBottomWidth="1px" bg="#1A202C" color='#fff' display={{base: 'none', md: 'block'}}>
                <Container
                    minW={'100%'}
                    py={{
                        base: '1',
                        md: '3.5',
                    }}
                >
                    <Box maxW={'1170px'}
                         mx={'auto'}
                    >
                        <Stack
                            spacing={{base: 1, md: 4}}
                            justify={{
                                base: 'flex-end',
                                md: 'space-between',
                            }}
                            justifyContent={{
                                base: 'center',
                                md: 'flex-end'
                            }}
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <Stack
                                direction={{
                                    base: 'row',
                                    md: 'row',
                                }}
                                spacing={{base: 1, md: 3}}
                                align={{
                                    base: 'center',
                                    md: 'center',
                                }}
                            >
                                <Text display={'flex'} alignItems={'center'} gap={2} fontWeight={'bold'}
                                      size={'xs'}><BsClockHistory/>24/7</Text>
                                <Button size={'xs'} variant={'ghost'} color='#FFA903' _hover={{bg: '#1A202C'}} as={'a'}
                                        href={SiteData.telLink}>Request Free Quote</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Container>
            </Box>

            <NavBar/>
        </>
    );
};

export default Header;