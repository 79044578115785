import {Button, Popover, PopoverContent, PopoverTrigger, Stack, useDisclosure,} from '@chakra-ui/react'
import {PopoverIcon} from './PopoverIcon'

export const DocumentPopover = ({items, title, href}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger="hover" openDelay={0}>
            <PopoverTrigger>
                <Button as={'a'} href={href} rightIcon={<PopoverIcon isOpen={isOpen}/>}>{title}</Button>
            </PopoverTrigger>
            <PopoverContent p="2" maxW="fit-content">
                <Stack spacing="0" alignItems="stretch">
                    {items?.map(({path, name}) => (
                        <Button as={'a'} href={path} key={name} variant="tertiary" justifyContent="start">
                            {name}
                        </Button>
                    ))}
                </Stack>
            </PopoverContent>
        </Popover>
    )
}
