import React from 'react';
import {SiteData} from "../../Constants/siteData";


const Map = ({height}) =>
    process.env.REACT_APP_GOOGLE_API_KEY ? (
        <iframe
            title={'google-map-service-area'}
            height={height}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
    &q=${SiteData.city}`}>
        </iframe>
    ) : null


export default Map;